import React from 'react';

import picture from '../../assets/images/me.jpg';

const ProfilePicture = () => (
  <div
    className="profile-picture"
    style={{
      background: `url(${picture})`,
      backgroundSize: 'cover',
    }}
  />
);

export default ProfilePicture;
