import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ExternalLink from '../common/ExternalLink';

const TimelineItem = ({ classes, markerClasses, data }) => {
  const containerClasses = classNames('timeline-item', classes);
  const innerClasses = classNames('timeline-marker', markerClasses);

  return (
    <div className={containerClasses}>
      <div className={innerClasses} />
      <div className="timeline-content">
        <p className="heading">{data.when}</p>
        <p className="company">
          <ExternalLink title={data.company} url={data.url} />
        </p>
        <p className="job">{data.job}</p>
      </div>
    </div>
  );
};

TimelineItem.defaultProps = {
  classes: '',
  markerClasses: '',
};

TimelineItem.propTypes = {
  classes: PropTypes.string,
  markerClasses: PropTypes.string,
  data: PropTypes.object.isRequired,
};

export default TimelineItem;
