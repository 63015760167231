import React, { Component } from 'react';

import PresentationLine from './PresentationLine';
import strings from '../../assets/lang/en';
import { generateKey } from '../../js/common';

class PresentationText extends Component {
  rederLines = () => {
    const { presentationText } = strings;

    return Object.values(presentationText).map((line) => {
      const key = generateKey(line.text);
      return <PresentationLine key={key} item={line} />;
    });
  }

  render() {
    return (
      <div className="presentation-text">
        <ul>{this.rederLines()}</ul>
      </div>
    );
  }
}

export default PresentationText;
