import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TimelineHeader from './TimelineHeader';
import TimelineItem from './TimelineItem';
import { generateKey } from '../../js/common';
import background from '../../assets/data/background';

class Timeline extends Component {
  renderItems = () =>
    Object.values(background).map((item) => {
      const key = generateKey(item.company);
      return (
        <TimelineItem
          key={key}
          classes="is-primary"
          markerClasses="is-primary is-icon"
          data={item}
        />
      );
    })

  render() {
    return (
      <div className="timeline is-centered">
        <TimelineHeader classes="is-medium is-primary" heading="Now" />
        {this.renderItems()}
        <TimelineHeader
          classes="is-medium is-primary"
          heading="A long time ago in a galaxy far, far away..."
        />
      </div>
    );
  }
}

export default Timeline;
