const skills = {
  html: { skill: 'html', percent: 95, strokeColor: '#e74c3c' },
  css: { skill: 'css', percent: 90, strokeColor: '#2c75c0' },
  js: { skill: 'javascript', percent: 75, strokeColor: '#f0cb37' },
  react: { skill: 'react', percent: 65, strokeColor: '#61dafb' },
  nodejs: { skill: 'nodejs', percent: 65, strokeColor: '#689f63' },
  wordpress: { skill: 'wordpress', percent: 75, strokeColor: '#27b8e2' },
  // php: { skill: 'php', percent: 70, strokeColor: '#9c66b0' },
  // express: { skill: 'express', percent: 50, strokeColor: '#ffffff' },
  // mongodb: { skill: 'mongodb', percent: 50, strokeColor: '#4fa94b' },
  // sql: { skill: 'sql', percent: 50, strokeColor: '#acadad' },
};

export default skills;
