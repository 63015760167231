import React from 'react';

import Layout from '../components/common/Layout';
import SEO from '../components/common/SEO';
import Home from '../components/home/Home';
import Presentation from '../components/presentation/Presentation';
import Background from '../components/background/Background';
import Skills from '../components/skills/Skills';
import Contact from '../components/contact/Contact';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={['Dimitri Tworowski', 'Curriculum Vitae', 'web developer', 'Ingenum', 'Guidimmo']}
    />
    <Home />
    <Presentation />
    <Background />
    <Skills />
    <Contact />
  </Layout>
);

export default IndexPage;
