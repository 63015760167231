import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TimelineHeader = ({ classes, heading }) => {
  const spanClasses = classNames('tag', classes);

  return (
    <header className="timeline-header">
      <span className={spanClasses}>{heading}</span>
    </header>
  );
};

TimelineHeader.defaultProps = {
  classes: '',
};

TimelineHeader.propTypes = {
  classes: PropTypes.string,
  heading: PropTypes.string.isRequired,
};

export default TimelineHeader;
