const background = {
  ingenum: {
    company: 'Ingenum',
    url: 'https://ingenum.be',
    when: 'April 2019 - Present',
    job: 'Web Developer Consultant',
  },
  guidimmo: {
    company: 'Guidimmo',
    url: 'https://guidimmo.be',
    when: 'April 2017 - November 2018',
    job: 'Lead developer of the project, frontend and backend functionalities',
  },
  gryfos: {
    company: 'Gryfos',
    url: 'https://gryfos.com',
    when: 'February - April 2016',
    job:
      '15 weeks intership as web developer, worked on websites running WordPress and creation of small plugins for clients',
  },
  helha: {
    company: 'HELHa Mons',
    url: 'https://helha.be',
    when: 'September 2013 - September 2016',
    job: 'Student in business computing, graduated with distinction',
  },
};
export default background;
