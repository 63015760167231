import React from 'react';
import PropTypes from 'prop-types';

const PresentationLine = ({ item }) => (
  <li>
    <i className={item.icon ? `icon-${item.icon}` : ''} />
    <span className="line-text">{item.text}</span>
  </li>
);

PresentationLine.propTypes = {
  item: PropTypes.object.isRequired,
};

export default PresentationLine;
