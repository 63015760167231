import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typed from 'typed.js';

class TypedReact extends Component {
  componentDidMount() {
    const { strings } = this.props;
    const options = {
      strings,
      typeSpeed: 50,
      backSpeed: 50,
      backDelay: 1000,
      loop: true,
    };

    this.typed = new Typed(this.el, options);
  }

  componentWillUnmount() {
    this.typed.destroy();
  }

  render() {
    return (
      <span
        ref={(el) => {
          this.el = el;
        }}
        className="typed"
      />
    );
  }
}

TypedReact.propTypes = {
  strings: PropTypes.array.isRequired,
};

export default TypedReact;
