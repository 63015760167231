import React from 'react';

import Section from '../common/Section';
import PresentationText from './PresentationText';
import ProfilePicture from './ProfilePicture';

import strings from '../../assets/lang/en';
import quotes from '../../assets/data/quotes';

const Presentation = () => (
  <Section
    id="presentation"
    title={strings.sections.presentation}
    subtitle={quotes.presentation.quote}
  >
    <ProfilePicture />
    <PresentationText />
  </Section>
);

export default Presentation;
