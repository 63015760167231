import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Section extends Component {
  renderTitle = (title) => {
    if (!title) {
      return null;
    }
    return <h1 className="title">{title}</h1>;
  }

  renderSubtitle = (subtitle) => {
    if (!subtitle) {
      return null;
    }
    return <h2 className="subtitle">{subtitle}</h2>;
  }

  render() {
    const {
      id, title, subtitle, children,
    } = this.props;

    return (
      <section id={id} className="section">
        <div className="container">
          {this.renderTitle(title)}
          {this.renderSubtitle(subtitle)}
          <hr className="title-divider" />
          <div className="section-content">{children}</div>
        </div>
      </section>
    );
  }
}

Section.defaultProps = {
  title: '',
  subtitle: '',
};

Section.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Section;
