import React from 'react';
import Particles from 'react-particles-js';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import TypedReact from '../common/TypedReact';

import ParticlesJSON from '../../assets/data/particles';
import strings from '../../assets/lang/en';

const Home = () => {
  const { typed } = strings;

  return (
    <section id="home">
      <div className="container">
        <div className="home-text">
          <div className="home-left">
            <span>Hi</span>
          </div>
          <div className="home-right">
            <span className="firstname">&apos;m Dimitri</span>
            <span className="job">work in IT</span>
            <span className="special">
              &apos;m a&nbsp;
              <TypedReact strings={typed} />
            </span>
          </div>
        </div>
      </div>
      <AnchorLink offset={0} href="#presentation" className="scroll-button" />
      <Particles className="particles" canvasClassName="particles-canvas" params={ParticlesJSON} />
    </section>
  );
};

export default Home;
