import React, { Component } from 'react';
import SkillCircle from './SkillCircle';
import SkillLine from './SkillLine';

import skills from '../../assets/data/skills';

const learning = { skill: 'learning', percent: 100, strokeColor: '#c49b66' };
class SkillsList extends Component {
  renderSkills = () =>
    Object.values(skills).map(skill => <SkillCircle key={skill.skill} data={skill} />)

  render() {
    return (
      <div className="skills">
        {this.renderSkills()}
        <SkillLine key="learning" data={learning} />
      </div>
    );
  }
}

export default SkillsList;
