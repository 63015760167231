import React from 'react';

import Section from '../common/Section';
import Linkedin from '../../assets/images/linkedin.png';

const Contact = () => (
  <Section id="contact" title="Contact" subtitle="« If opportunity doesn’t knock, build a door. »">
    <span className="contactText">
      <p>
        If you want to get in touch with me, do not hesitate to send me your message through my
        Linkedin profile
      </p>
    </span>
    <a
      href="https://www.linkedin.com/in/dimitri-tworowski-a59491b4"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={Linkedin} alt="linkedin link" />
    </a>
  </Section>
);

export default Contact;
