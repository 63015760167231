import React from 'react';

import Section from '../common/Section';
import Timeline from './Timeline';

const Background = () => (
  <Section
    id="background"
    title="Background"
    subtitle="« Yesterday is history, tomorrow is a mystery. »"
  >
    <Timeline />
  </Section>
);

export default Background;
