import React from 'react';

import Section from '../common/Section';
import SkillsList from './SkillsList';

const SectionSkills = () => (
  <Section id="skills" title="Skills" subtitle={"« Excellence is not a skill, it's an attitude. »"}>
    <SkillsList />
  </Section>
);

export default SectionSkills;
