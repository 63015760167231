import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Circle } from 'rc-progress';
import classNames from 'classnames';

class SkillCircle extends Component {
  constructor(props) {
    super(props);
    this.state = { percent: 0 };
  }

  componentDidMount() {
    this.increase();
  }

  increase = () => {
    let { percent: statePercent } = this.state;
    const {
      data: { percent: skillPercent },
    } = this.props;

    if (statePercent >= skillPercent) {
      clearTimeout(this.tm);
      return;
    }

    this.setState({ percent: ++statePercent });

    this.tm = setTimeout(this.increase, 10);
  }

  restart = () => {
    clearTimeout(this.tm);
    this.setState({ percent: 0 }, () => {
      this.increase();
    });
  }

  render() {
    const { percent } = this.state;
    const {
      data: { skill, trailColor, strokeColor },
    } = this.props;

    const classes = classNames('skill', 'circle', skill);

    return (
      <div className={classes}>
        <Circle
          percent={percent}
          strokeWidth={1}
          trailWidth={1}
          trailColor={trailColor || 'rgba(0, 0, 0, .1)'}
          strokeColor={strokeColor}
        />
        <img
          src={require(`../../assets/images/skills/${skill}.svg`)}
          alt="skill-logo"
          onMouseEnter={this.restart}
        />
        <span className="skill-name" style={{ color: strokeColor }}>
          {skill.toUpperCase()}
        </span>
      </div>
    );
  }
}

SkillCircle.propTypes = {
  data: PropTypes.shape({
    skill: PropTypes.string.isRequired,
    percent: PropTypes.number.isRequired,
    trailColor: PropTypes.string,
    strokeColor: PropTypes.string,
  }).isRequired,
};

export default SkillCircle;
